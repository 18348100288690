.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 48px 100px 72px;
}

.mottoContainer {
  background-color: #e4dff8;
  text-align: center;
  color: black;
  margin-bottom: 72px;
  padding: 32px;
  border: 1px black solid;
  border-radius: 16px;
  position: relative;
  font-style: italic;
  line-height: 32px;
}

.mottoContainer::before {
  content: '';
  border-radius: 16px;
  background-color: black;
  color: black;
  position: absolute;
  left: 0;
  right: -8px;
  top: 8px;
  bottom: -8px;
  z-index: -1;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 64px;
}
.gridItem {
  background: #402074;
  border-radius: 32px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  
}

.gridItem img {
  width: 64px;
  height: 64px;;
}
.gridContent {
  background-color: white;
  padding: 16px;
  border-radius: 32px;
  text-align: center;
  flex: 1;
}
@media screen and (max-width: 600px) {

  .container {
    flex-direction: column;
    padding: 32px;
  }
  .gridContainer {
    grid-template-columns: repeat(1, 1fr);
  }

}