.itemContainer{
  width: 28.333%;
  margin-bottom: 16px;
  cursor: pointer;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  border: 2px solid #F0ECFB;
  border-radius: 8px;
}

.coinsImage{
  
  width: 32px;
  margin: 8px 0 8px;
  
  
}

.coinsValue{
  font-size: 21px;
  font-weight: bold;
}

.coinsText{
  font-size: 12px;
  padding-bottom: 8px;
  
}

.priceContainer{
  background-color: #6940DB19;
  width: 100%;
  padding: 12px 0 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 6px 6px;
}

.discountText{
  font-size: 10px;
  position: absolute;
  text-align: center;
  top: -5px;
  color: white;
  background-color: #481A83;
  padding: 2px 4px;
  font-weight: bold;
  border-radius: 8px;
}

.price{
  font-size: 12px;
  font-weight: bold;
  color: #F0127E;
}

.packageListContainer{
  display: flex;
  width: 90%;
  align-self: center;
  margin: auto;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}


.popularItem .item {
  border: 2px solid #FFC62F;
  border-radius: 8px;
}

.popularText {
  font-size: 10px;
  position: absolute;
  text-align: center;
  top: -8px;
  color: white;
  background-color: #FF4F6D;
  padding: 2px 4px;
  font-weight: bold;
  border-radius: 8px;
}

.popularItem .priceContainer {
  background-color: #FFC62F;
}
.popularItem .price {
  color: #481A83;
}

.popularItem .discountText {
  background-color: white;
  color: #481A83;
  border: 1px solid #481A83;
  box-sizing: border-box;
  top: -7px
}

.strikePrice {
  display: inline;
  text-decoration: line-through;
  margin-right: 2px;
  opacity: 0.4;
  color: #6940DB;
}
@media screen and (min-width:1024px) {
  .packageListContainer {
    width: 100%;
    align-items: flex-start;
  }
  .itemContainer {
    width: 19%;
  }
  .item {
    border: 2px solid rgba(0,0,0,0.1);
    border-radius: 8px;
    margin-bottom: 8px;
  }
}