.profile-review-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    margin-top: 32px;
    margin-bottom: 8px;
}

.profile-review-container {
    display: flex;
    overflow: hidden;
}

.profile-review-carousel {
    display: flex;
    transform: translateX(0);
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
}

.profile-review-section {
    background-color: #F5F6F7;
    border-radius: 16px;
    margin: 0px 16px;
    flex-shrink: 0;
    width: calc(100vw - 68px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px;
}


.profile-review-comment {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #474747;
    margin-bottom: 14px;
}

.profile-review-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #171717;
    margin-top: 12px;
}