.carouselImage {
  object-fit: contain;
  height: 60vh;
}

.carouselImage50 {
  height: 30vh;
}
.carouselImage20 {
  height: 42.25vw;
  width: 90vw!important;
}
.carouselImage100 {
  height: 80vh;
  /* width: 90vw!important; */
}
.carouselContainer {
  height: 100%;
}

.slickDot {
  height: 5px;
  width: 18px;
  border-radius: 18px;
  bottom: 10px;
  background-color: #FFFFFF7F;
  margin: 0;
}

.slickDots20 {
  bottom: -15px;
}
.slickDots {
  bottom: -40px;
}

@media screen and (min-width:600px) {
  .carouselImage20 {
    height: 100px!important;
    width: 100px!important;
  }
}