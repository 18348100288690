.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statusContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 20vh;
  width: 100%;
  align-items: center;
  text-align: center;
  transition: 0.3s ease all;
}
.processingText {
  font-weight: bold;
  margin: 16px 0;
  color: #4AA9FF;
  font-size: 21px;
}
.pendingText {
  font-weight: bold;
  margin: 16px 0;
  color: #F5771C;
  font-size: 21px;
}
.processingDescription {
  font-size: 12px;
  width: 60%;

}
.loader {
  border: 4px solid #D9D9D9; 
  border-top: 4px solid #4AA9FF; 
  border-right: 4px solid #4AA9FF; 
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}
.faqOpened {
  margin-top: 74px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.features {
}
.coinsContainer {
  display: flex;
  align-items: center;
}

.coinIcon {
  height: 28px;
}

.coins {
  font-weight: bold;
  font-size: 28px;
  margin-left: 8px;
}

.creditedText {
  font-weight: bold;
  font-size: 16px;

}

.successText {
  font-weight: bold;
  margin: 16px 0 30px;
  font-size: 21px;
}
.timer {
  width: 70%;
  text-align: center;
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: medium;
}

.timeLeft {
  color: #FF3D66;
}

.footer { 
  position: fixed;
  bottom: 2vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.tryAgainButton {
  margin: 20px 0;
  padding: 12px 16px;
  border: 1px solid #4AA9FF;
  color: #4AA9FF;
  width: 90%;
  border-radius: 24px;;
  box-sizing: border-box;
  font-weight: bold;
  cursor: pointer;
}

.helpCenterButton {
  display: flex;
  padding: 12px 16px;
  border: 1px solid #C2C7D0;
  color: #7E7E7E;
  width: 60%;
  border-radius: 24px;
  margin-bottom: 24px;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
}
.caretRightIcon {
  margin-left: auto;
  transition: 0.3s ease all;

}
.buttonText {
  margin-left: 8px;
  font-weight: bold;
  font-size: 12px;
}
.faqContainer {
  max-width: 90%;
  margin: 16px auto;
  overflow: hidden;
  transition: 0.3s ease all;
  overflow: scroll;
  max-height: 20vh;
}

.question {
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 8px;
}

.answer {
  font-size: 12px;
  margin-bottom: 24px;
}
.leftPane {
  display: none;
}
@media screen and (min-width:600px) {
  .helpCenterButton {
    width: unset;
    margin: 0 0 8px;
  }
  .processingDescription {
    width: 80%;
  
  }

  .leftPane  {
    display: block;
  }
  .desktop {
    display: block;
    height: 100vh;
    box-sizing: border-box;
    padding: 15vh 10vw;
    box-sizing: border-box;
    background-color: #F1F1F1;
  }
  .screenContainer {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 4px 4px 10px rgba(0,0,0,0.1);
  }
  .leftPane, .rightPane {
    flex:1;
    overflow: scroll;
  }
  .leftPane {
    background: #8547FF;
    color: white!important;
  }
  .rightPane {
    padding: 16px;
    box-sizing: border-box;
    position: relative;
  }
  .buttonText {
    margin-right: 8px;
  }
  .footer {
    position: absolute;
    bottom: 24px;
  }
  .timer, .successButton {
    display: none;
  }
  .faqOpened {
    margin: 0;
  }
  .statusContainer{
    margin-top: 64px;
  }
  .faqOpened {
    margin-top: 0;
  }
}