.buttonContainer {
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  cursor: pointer;
  user-select: none;
  position: relative;;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  position: relative;
}
.shineContainer:before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  background-image: linear-gradient(
      120deg,
      rgba(255,255,255, 0) 30%,
      rgba(255,255,255, .2),
      rgba(255,255,255, 0) 70%
  );
  /* transform: translateX(-50%); */
  left: 0;
  top: 0;
  animation: shine 2s infinite ease-in-out;
}

@keyframes shine {
  0% {
    left: 0;
  }
  40% {
    left: 50%;
  }
  50% {
    left: 50%;
  }
  90% {
    left: 0%;
  }
  100%{
    left: 0%;
  }
}

.leftImage {
  height: 24px;
  margin-right: 8px;
}

.buttonText {
  color: white;
  text-align: center;
  font-weight: bold;
}