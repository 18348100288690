.slick-dots {
  color: red;
}

.slick-dots button {
  margin: 0;
}

.slick-dots .slick-active {
  color: white;
}

.slick-dot {
  background-color: rgba(255, 255, 255, .56);
}

.slick-active .slick-dot {
  background-color: white;
}

.slick-dots li {
  margin: 0 4px 0 0;
}

.slick-slide>div {
  display: flex;
  justify-content: center;
}