.container {
}

.headingContainer {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 2px solid black;
  cursor: pointer;
}

.heading {
  flex: 1;
  font-size: 28px;
}

.caretIcon {
  height: 24px;
  width: 24px;
  transition: all 0.2s ease-in;
  transform: rotate(0deg);
}

.childrenContainer {
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  a {
    display: none;
  }
}

.caretIconInverted {
  transform: rotate(180deg);
}

.caretIconNormal {
  transform: rotate(0deg);
}

.openChildren {
  max-height: 100px;
}

.closedChildren {
  max-height: 0;
}

@media screen and (max-width: 600px) {
  .heading {
    font-size: 20px;
  }
}
