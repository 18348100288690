.checklist-section-container {
  display: flex;
  flex-direction: column;
  padding: 0px 200px;
  align-items: center;
}
.top-heading {
  margin: 72px 0 48px;
}
.checklist-section-container::before {
  /* background-size: calc(100% - 100px) calc(100% - 100px); */
  background: #C5A0FF32;
  content: ' ';
  position: absolute;
  width: 100%;
  height: 30%;
  z-index: -1;
}



.checklist-container {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: #FF3BA4;
  border: 3px solid white;
}

.hero-image {
  width: 50%;
}

.checklist {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.checklist-item {
  display: flex;
  margin: 24px 16px;
  align-items: center;
}

.check {
  width: 32px;
  margin-right: 32px;
}

.check-text {
  color: white;
  font-weight: bold;
}

.bottom-heading {
  text-align: center;
  margin: 96px 0;

}

@media screen and (max-width: 600px) {
  .checklist-section-container {
    padding: 16px;
  }
  .checklist-container  {
    flex-direction: column;
    border: none;
    overflow: hidden;
    border-radius: 8px;
  }
  .hero-image {
    width: 100%;
  }

  .bottom-heading {
    text-align: center;
    margin: 64px 0;
    font-size: 32px;
    width: 90%;
  
  }
  .checklist-item {
    margin: 16px 16px;
  }
  .top-heading {
    margin: 72px 0 48px;
    text-align: center;
  }
}
