.profile-review-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

.profile-reviews {
    margin-bottom: 64px;
}

.profile-review {
    display: flex;
    background: #F5F6F7;
    border-radius: 12px;
    margin: 16px 40px 16px;
    padding: 12px 28px 18px 18px;
}

.profile-review-body {
    margin-left: 8px;
}

.profile-review-rating {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #474747;
    display: flex;
    align-items: center;
}

.profile-review-handle {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #7E7E7E;
    margin-top: 8px;
    margin-bottom: 4px;
}

.profile-review-text {
    color: #7E7E7E;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}