.container {
  display: flex;
  flex: 1;
  padding: 48px 100px 72px;
}

.benefits-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.benefits-content>h1 {
  margin-bottom: 0;
  font-size: 48px;
}

.benefits-content>p {
  color: #FF194C;;
  font-size: 28px;
}

.benefit-icon {
  width: 48px;
  height: 48px;
  margin-right: 16px;
  ;
}

.benefit-container {
  display: flex;
  align-items: flex-start;
}

.benefit-text h3,
p {
  color: #303030;
  margin: 0 0 8px 0;
}

.benefit-text h3 {

  font-size: 24px;

}

.benefit-text p {
  font-size: 14px;
}

.benefits-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 32px;
  margin-top: 48px;
}

.benefits-image-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex: 1;
}

.benefits-image {
  width: 90%;
}

@media screen and (max-width: 600px) {
  .benefit-container {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  .benefit-text {
    text-align: center;
    line-height: 24px;
  }
  .container {
    flex-direction: column;
    padding: 32px;
  }
  .benefits-image {
    display: none;
  }
  .benefits-content h1 {
    text-align: center;
  }
  .benefits-content {
   align-items: center;
  }
}