@media (max-width: 640px) {
  .docContainer {
    padding: 80px 20px 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    word-break: break-word;

  }
}

@media (min-width: 641px) {
  .docContainer {
    max-width: 900px;
    margin: auto;
    padding: 84px 16px 24px;
    word-break: break-word;

  }
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
p,
li {
  margin: 8px 0;
}
th,
td {
  padding: 8px;
}

th {
  background-color: rgba(0, 0, 0, 0.1);
}

td {
  vertical-align: top;
}

table {
  margin: 12px 0;
}

a {
  color: blue;
  text-decoration: underline;
}

.frndcommunity{
  text-align: center;
}