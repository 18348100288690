.language-dropdown {
  display: flex;
  border: 1px solid #c4c4c4;
  padding: 0 16px;
  border-radius: 12px;
  position: relative;
  height: 100%;
}
.caret-left {
  display: none;
}
.language-dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.selected-language {
  margin: 0 8px;
}

.caret {
  margin-left: 8px;
  width: 8px;
  object-fit: contain;
}

.caret-up {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.caret-down {
  transition: transform 0.3s ease;
}

.language-dropdown-menu {
  list-style: none;
  margin: 0;
  padding: 8px 16px;
  width: 200px;
  position: absolute;
  top: calc(100% + 16px);
  right: 0;
  z-index: 1;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #F1F1F1;
  box-shadow: 2px 4px 60px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.no-select {
  margin: 4px 0 8px;
  padding: 4px 0;
  width: 100%;
}
.list-item:hover {
  background-color: #f2f2f2;
}

.language-dropdown-menu.show {
  opacity: 1;
  transform: translateY(0);
}

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 4px 0;
  padding: 8px 0;
  border-radius: 4px;
  padding-left: 4px;
  width: 100%;
  box-sizing: border-box;
}

.list-item img {
  width: 24px;
  height: 24px;
}

@media (max-width: 768px) {
  .language-dropdown-menu {
    top: -64px;
    width: calc(100% - 64px);
    left: 0;
    height: 100vh;
    box-shadow: none;
    padding: 0 32px;
  }

  .language-dropdown {
    position: unset;
    box-sizing: border-box;
    width: 100%;
    height: unset;
  }

  .language-dropdown-toggle {
    flex: 1;
    margin: 16px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding: 0;
  }
  .no-select {
    margin: 16px 0 24px!important;
    padding: 4px 0;
    width: 100%;
    text-align: center;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .list-item {
    font-size: 24px;
    margin: 16px 0;
  }

  .caret-left {
    margin: auto auto auto 0;
    transform: rotate(90deg);
    width: 16px;position: absolute;
    left: 32px;
    display: block;
  }
}
