.navBarContainer {
  display: flex;
  background-color: #eee;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  /* border: 1px solid black; */
  box-sizing: border-box;
  height: 72px;
  position: relative;
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 1000;
}

.navBarContainer h3 {
  cursor: pointer;
}

.navBarContainer>* {
  flex: 1;
}

.menuOptions {
  display: flex;
  flex: unset;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.menuOption {
  cursor: pointer;
  border-bottom: solid 1px transparent;
  transition: 0.2s all ease;
  height: 100%;
  flex: 1;
  width: 100%;
  align-items: center;
  display: flex;
  padding: 0 16px;
  box-sizing: border-box;

}

.menuOption:hover {
  border-bottom: solid 1px red;
}

.background {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0px;
  left: 0px;
  opacity: 0;
  transition: all 0.2s ease-in;
  display: none;
}


.menuOption:hover~.background {
  opacity: 1;
  display: block;
}

.actionIconContainer {
  display: none;
}

.subMenuContainer {
  background-color: #fff;
  /* width: 100vw; */
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  transition: all .2s ease;
  opacity: 0;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  z-index: 2;
  height: 0;
}

.subMenuContent {
  width: 50vw;
  display: flex;
  flex-wrap: wrap;


}
.header {
  position: relative;;
}
.menuOption:hover>.subMenuContainer {
  opacity: 1;
  top: 72px;
  display: flex;
  height: auto;
}

.subMenuItemContainer {
  background-color: white;
  padding: 16px;
  width: calc(50% - 32px);
  box-sizing: border-box;
  margin: 16px;
  cursor: pointer;
}

.subMenuItemHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subMenuItemTitle {
  font-size: 16px;

}

.subMenuItemCta {
  filter: invert(29%) sepia(100%) saturate(5705%) hue-rotate(337deg) brightness(103%) contrast(101%);
}

.subMenuItemText {}

.content {
  /* padding-top: 100px;  */
}

/* mobile styles */

@media screen and (max-width: 600px) {
  .menuOptions {
    position: absolute;
    flex-direction: column;
    background: #eee;
    width: 100%;
    height: unset;
    padding: 16px;
    box-sizing: border-box;
    top: -400px;
    left: 0;
    gap: 16px;
    transition: all 0.2s ease-in;
    z-index: 2;

  }

  .menuOption:hover~.background {
    display: none;
  }
  

  .menuOptionsVisible {
    top: 72px;
    z-index: 2;
  }

  .mobileMenuIconContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .actionIconContainer {
    display: block;
  }

  .subMenuContainer {
    display: none !important;
  }

  .menuOption:hover {
    border-bottom: none
  }

  .menuOption p {
    font-weight: 600;
  }

  .navBarContainer {
    padding: 16px;
  }
}