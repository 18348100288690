.dot-container {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    overflow: hidden;
}

.dot {
    background-color: #DFE2E6;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: 8px;
}

.active {
    background-color: #A4A4A4;
}