.form-section-container {
  margin: 48px 100px;
  background: #FF194C;
  border-radius: 32px;
  display: flex;
}
.form-container {
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: 48px 72px;
}
.form-description {
  color: white;
  width: 50%;
  margin: 8px 0 32px;
  
}
.form-heading {
  color: white;
  width: 70%;
  font-size: 64px;
  margin: 8px 0;

}
.form-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}
.form-image {
  height: 550px;
}

form {
  width: 80%;
}

.form-text-fields-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  gap: 16px;
}
label {
  color: white;
}
.form-text-field {
  background: none;
  border: 1px solid white;
  outline: none;
  border-radius: 4px;
  color: white;
  padding: 16px;
  width: 100%;
  margin: 16px 0;
  font-weight: bold;
}
.form-text-field::placeholder {
  color: white;
}
.form-radio-field {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #FF194C;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  outline: 1px solid white;
  background-color: transparent;
}

.form-radio-field:checked {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #FF194C;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  outline: 1px solid white;
  background-color: white;
}
.form-radio-field {
  margin: 16x 0;
}

.gender-label {
  margin: 16px 0;
  font-weight: bold;
}

button {
  background-color: white;
  color: #FF194C;
  padding: 12px 64px;
  font-weight: bold;
  outline: none;
  border: none;
  font-size: 20px;
  border-radius: 4px;
  margin: 16px 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
button:hover {
  transform: scale(1.02);
}

button:disabled {
  background-color: #aaa;
}
.form-radio-field {
cursor: pointer;
}
.message {
color: white;
}
.radio-label {
  display: flex;
  align-items: center;

}
.radio-options-container {
  display: flex;
  gap: 24px;
  margin: 24px 0;
}

@media screen and (max-width: 1300px) {
  .form-text-fields-container {
    flex-direction: column;
    width: 100%;
    gap: 0;
  }
}
@media screen and (max-width: 600px) {
  .form-section-container {
    flex-direction: column;
    margin: 16px;
  }
  .form-image-container {
    display: none;
  }
  .form-container  {
    padding: 32px;
  }
  .form-heading, .form-description {
    width: unset;
  }

  .form-heading {
    font-size: 36px;
  }
  .form-text-fields-container {
    flex-direction: column;
    width: 100%;
    gap: 0;
  }
  form {
    width: 100%;
  } 
  .form-text-field {
    width: unset;
    margin: 8px 0;
  }

  button {
    width: 100%;
  }

}