.investors {
    margin: 64px 87px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.investor-text {
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    color: #18191F;

}

.investor-container {
    display: flex;
}

.investor {
    margin-right: 90px;
    cursor: pointer;
}

.investor-container img{
    height: 90px;
    width: 160px
}

@media screen and (max-width: 767px) {
    .investor-container {
        flex-direction: column;
    }
    .investors {
        flex-direction: column;
        margin: 0;
    }
    .investor {
        margin: 30px;
    }
    .investor-text {
        font-size: 32px;
        line-height: 60px;    
        text-align: center;
        margin: 64px auto 30px;
    }
}