.benefitContainer{
  width: 90%;
  margin: 32px auto 0;
}

.benefitHeading{
  text-align: center;
  font-weight: bold;

}

.benefitsList{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 16px 0 32px;

}

.benefit{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
}

.benefitImage{
  width: 56px;
  margin-bottom: 8px;

}

.benefitText{
  font-size: 14px;
  text-align: center;
}

.white {
  color: white;
}
