.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 48px 100px 72px;
  background: rgba(255, 220, 228, 1);
  align-items: center;

}

.mottoContainer {
  background-color: #e4dff8;
  text-align: center;
  color: black;
  margin-bottom: 72px;
  padding: 32px;
  border: 1px black solid;
  border-radius: 16px;
  position: relative;
  font-style: italic;
  line-height: 32px;
}

.mottoContainer::before {
  content: '';
  border-radius: 16px;
  background-color: black;
  color: black;
  position: absolute;
  left: 0;
  right: -8px;
  top: 8px;
  bottom: -8px;
  z-index: -1;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 32px;
  gap: 5vw;
}

.gridItem {
  border-radius: 32px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 7px 0px 0px rgba(0, 0, 0, 1);
  border: 2px solid rgba(0, 0, 0, 1);
  background: white
}

.gridItem img {
  width: 128px;
  height: 128px;
}

.gridContent {
  background-color: white;
  padding: 16px;
  border-radius: 32px;
  text-align: center;
  flex: 1;
}

.subheadings1,
.subheadings2 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.subheadings1 {
  margin-top: 16px;
}

.button {
  background-color: #FF4D75;
  color: #fff;
  padding: 12px 64px;
  font-weight: bold;
  outline: none;
  border: none;
  font-size: 20px;
  border-radius: 4px;
  margin: 64px 0 32px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  width: 30vw;
}

.button:hover {
  transform: scale(1.02);
}

@media screen and (max-width: 600px) {

  .subheadings1,
  .subheadings2 {
    font-size: 14px;
    text-align: center;
    font-weight: normal;
  }

  .container * {
    font-size: 14px;
  }

  .container {
    flex-direction: column;
    padding: 32px;
  }

  .gridContainer {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 0;
  }

  .gridItem {
    margin: 32px 0 0!important
  }

  .gridContent h2 {
    font-size: 16px;
  }
  .button {
    width: 100%;
    font-size: 18px;

  }

}