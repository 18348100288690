.profile-features-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}


.profile-features-container {
    display: flex;
    overflow: auto;
    margin: 16px;
}

.profile-features-section {
    background: #F5F6F7;
    margin-right: 8px;
    width: calc(100vw - 116px);
    padding: 16px 32px;
    border-radius: 24px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profile-features-desc {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 0px 18px;
}

.profile-features-button {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    border-radius: 36px;
    text-align: center;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

