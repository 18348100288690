.reviews-container {
    background: #F4F5F7;
    padding: 60px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reviews-heading {
    font-weight: 900;
    font-size: 32px;
    line-height: 60px;
    text-align: center;
    color: #18191F;
    font-family: "Lato", sans-serif;
}

.reviews-block {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 80%;

}

.review-rating {
    text-align: center;
    margin-bottom: 20px;
}

.review-text {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: #18191F;
}

.review-block {
    padding: 28px;
    border-radius: 10px;
    background-color: #FFF;
    margin-bottom: 30px;
}

.review-block::after {
    content: '';
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    width: 0;
    height: 0;
    bottom: -44px;
    border-top: 24px solid #ffffff;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    justify-content: center;
}

.review-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
}

.reviewer-name {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #18191F;
}


@media screen and (max-width: 767px) {
    .reviews-block {
        flex-direction: column;
        width: 100%;
    }
    .review-section {
        width: 100%;
        margin-bottom: 42px;
    }
    .reviews-heading {    
        padding: 0 14%;
    }
    .reviewer-image {
        margin-top: 30px;
    }
    .reviews-container {
        padding-bottom: 0;
    }
    .review-block {
        margin: 0px 5px;
    }
}