.faqs-section-container {
  display: flex;
  padding: 32px 100px;
  min-height: 400px;
}

.faqs-hero-img-container{
  flex:1;
}
.faq-hero-img {
  width: 70%;
}

.faqs-container {
  flex: 1;
}
.faqs-container h1 {
  padding: 0 12px 16px 0;
}
@media screen and (max-width: 600px) {
  .faqs-section-container {
    flex-direction: column;
    padding: 16px;
    height: unset;
    align-items: center;
  }
  .faqs-container h1 {
    padding: 0px;
  }
  .faqs-hero-img-container{
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 32px;
  }
  .faq-hero-img {
    width: 100%;
  }
}