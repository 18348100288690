.nav-list {
    display: flex;
    /* margin-top: 24px; */
    margin-bottom: 8px;
}

.nav-list-item {
    margin-right: 24px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
}

.super-footer-container {
    color: white;
    background: #0B0D17;
    padding: 60px 165px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-container {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 64px;
    margin-top: 24px;
}

.footer-download {
    text-align: right;
    margin-bottom: 24px;
}

.company-title {
    margin-top: 8px;
    color: #606060;
    align-self: center;
}

.cursor-pointer {
    cursor: pointer;
}

.footer-button-container {
    display: none !important;
}

.footer-container a {
    color: white;
    text-decoration: none;
}

.address {
    text-align: right;
}

.social-icons {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
}

.social-icons img {
    width: 32px;
    height: 32px;
    margin-bottom: 8px;
}

@media screen and (max-width: 767px) {

    .super-footer-container {
        padding: 64px 32px;
    }
    .footer-button-container {
        display: flex !important;
    }

    .footer-container {
        box-sizing: border-box;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 60px;
    }

    .nav-list-item {
        margin: 0;
        margin-bottom: 10px;
    }

    .nav-list {
        line-height: 26px;
        flex-direction: column;
        align-items: center;
        margin-bottom: 68px;
    }

    .footer-download {
        margin-top: 52px;
        text-align: center;
        margin-bottom: 10px;
    }

    .company-title {
        line-height: 26px;
        text-align: center;
        width: 100%;
    }

    .address {
        text-align: center;
    }

    .social-icons {
        justify-content: center;
        margin-top: 32px;
    }
}