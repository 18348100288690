.container {
  display: flex;
  flex: 1;
  padding: 64px 100px;
  gap: 64px;
  align-items: center;
}

.container img {
  height: 200px;
  aspect-ratio: 1;
}

.textContainer span {
  font-weight: bold;
  font-style: italic;
  color: #777;
}

.textContainer {
  overflow: hidden;
  padding: 32px;
  background: 
    linear-gradient(#fff 0 0) padding-box, /*this is your grey background*/
    linear-gradient(to right, #FFFFFF -9.75%, #FFC700 100%) border-box;
  border: 5px solid transparent;
  border-width: 1px 2px 1px 0px;
  border-radius: 16px;



}

@media screen and (max-width: 600px) {
  .container {
    flex-direction: column;
    padding: 64px 16px;
    gap: 32px;
  }

  .textContainer {
    padding: 0px;
    border: none;
    text-align: center;
  }
  .textContainer * {
    font-size: 14px;
  }
  .textContainer h2 {
    font-size: 16px;
  }
}