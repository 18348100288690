.heroSection {
  display: flex;
  gap: 32px;
}
.loaderContainer {
  height: 100vh;
  display: flex;
}



.transparencySectionContainer {
  background-color: #eee;
  padding: 32px 0;
}
.heroSection,
.tnCSection {
  margin: 64px auto;
  padding: 0 100px;
}

.browserText {
  width: 50%;
}

.heroTextContainer {
  flex: 0.9;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.heroTextContainer h1,
.tncTitle,
.browserTitle {
  font-size: 36px;
  margin-bottom: 0;
  margin-top: 40px;
}

.heroImageContainer {
  flex: 1;
}

.heroTextContainer p {
  width: 100%;
}

.heroImage {
  width: 100%;
  object-fit: contain;
}

.ctaLink {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.ctaLink p {
  font-weight: 600;
}

.browserGrid {
  display: flex;
  max-width: 100%;
  gap: 72px;
  margin-top: 16px;
}

.tilesContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 32px;
}
/* mobile styles  */
@media screen and (max-width: 600px) {
  .heroSection {
    flex-direction: column;
    margin: 32px 0;
    padding: 0;
  }

  .heroTextContainer {
    margin: 0 32px;
  }

  .heroTextContainer h1,
  .tncTitle,
  .browserTitle {
    font-size: 24px;
  }

  .tnCSection {
    padding: 0 16px ;
    box-sizing: border-box;
  }

  .browserText {
    width: unset
  }
  .browserGrid {
    flex-direction: column;
    gap: 8px;
  }

  .tilesContainer {
    gap: 8px;
  }

}