@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lato:ital,wght@0,100..900;1,100..900&display=swap");
html {
  scroll-behavior: smooth;
  scroll-padding-top: 16px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.primary-button {
  cursor: pointer;
  background: #ff194c;
  border-radius: 6px;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 40px;
  width: fit-content;
  text-decoration: none;
}
.red-link {
  color: #ff194c !important;
  font-weight: 600;
}
.black-link {
  font-weight: 600;
}
* {
  font-family: "Lato", sans-serif;
}

.ctaLink {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.ctaLink p {
  font-weight: 600;
}
