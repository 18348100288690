

.benefitsImage {
  width: 60%;
}

@media screen and (max-width: 600px) {

  .benefitsImage {
    display: none;
  }

}