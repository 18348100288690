
.benefitsImage {
  width: 500px;
}

.container h1 {
  font-size: 36px;
}

.subHeading {
  color: grey;
}
.container {
  display: flex;
  flex: 1;
  padding: 48px 100px 72px;
}

.benifitsContent {
  display: flex;
  flex-direction: column;

}
.benifitsContent p {
  color: black;
  font-size: 24px;
}

.container p {
  color: grey;
}
.imageContainer {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

@media screen and (max-width: 600px) {

  .benefitsImage {
    display: none;
  }

  .container {
    flex-direction: column;
    padding: 32px;
  }

  .benifitsContent {
    align-items: center;
  }
}