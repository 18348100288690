
.gridContainer {
  display: flex;
  gap: 200px;
  padding: 100px;
  justify-content: center;
}

.gridItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  width: 400px;
}

.gridItem video {
  width: 400px;
  aspect-ratio: 9/16;
  border-radius: 16px;
}

.gridContent {
  background-color: white;
  padding: 16px;
  border-radius: 32px;
  text-align: center;
  flex: 1;
}

@media screen and (max-width: 600px) {

  .container {
    flex-direction: column;
    padding: 32px;
  }

  .gridContainer {
    flex-direction: column;
    padding: 0 16px;
    box-sizing: border-box;
    align-items: center;
    gap: 32px;

  }

  .gridItem {
    width: 100%;
  }
  .gridItem h2 {
    font-size: 16px;
  }
  .gridItem p {
    font-size: 14px;
  }

  .gridItem video {
    width: 100%;
  }
}