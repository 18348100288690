.features-container {
    display: flex;
    padding: 64px 165px;
    flex-direction: column;
    background: rgba(255, 176, 219, 0.18);
}

.feature-heading {
    margin-bottom: 76px;
    font-weight: 900;
    font-size: 26px;
    line-height: 28px;
    color: #18191F;
    font-family: "Lato", sans-serif;
    align-self: flex-start;
}

.features-section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.feature {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
}

.bullet {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    width: 72px;
    background: #FFFFFF;
    border-radius: 50%;
}
.features {
    display: flex;
    justify-content: space-between;
    width: 78%;
}

.feature-img {
    width: 64px;
    height: 64px;
}

.feature-point {
    width: 240px;
}

.feature-bold-text {
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    color: #18191F;
    font-family: "Lato", sans-serif;
    width: 300px;
    margin-left: 40px;
}

.feature-text {
    font-size: 16px;
    line-height: 26px;
    color: #18191F;
    margin-top: 8px;
}


@media screen and (max-width: 767px) {
    .features {
        flex-direction: column;
        align-items: center;
    }
    .feature-point {
        margin-bottom: 50px;
    }
    .features-container {
        padding: 30px;
    }
    .feature-img-section {
        display: none;
    }
    .features {
        width: 100%;
    }
    .feature-bold-text {
        width: 220px;
    }
    .feature-heading {
        text-align: center;
        margin: 30px 15px 60px;
    }
}