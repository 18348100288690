
.buttonsContainer {
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  position: fixed;
  background-color: white;
}
.buttonContainer {
  flex:1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.timer {
  text-align: center;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 12px;
}

.timeLeft {
  color: #FF3D66;
}

.progressBar {
  height: 4px;
  background-color: #d9d9d9;
  margin: 0 0 12px 0;
}

.progress {
  height: 100%;
  animation: progress 5s linear;
  background-color: #009A66;
}

.container {
  padding-top: 72px;
  padding-bottom: 110px;
  position: relative;
  height: 100vh;
  width: 100vw;
  
}

@keyframes progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.video {
  height: calc(100vh - 80px);
  width: 100vw;
  object-fit: fill;
  position: absolute;
  top: 0;
}
.closeIcon {
  position: absolute;
  top: 32px;
  right: 32px;
  width: 18px;
}
.invert {
  filter: invert(1);
}
.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 0;
}

.reviewsImage {
  width: 150px;
  align-self: center;
  margin: 0 auto 32px;
};