.notFound {
  font-size: 100px;
  margin: 10vh auto;
  text-align: center;
}

.container {
  width: 90%;
  margin: 48px 100px;
}

.backContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
}

.backIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.backText {}

.heroContainer {
  display: flex;
  align-items: center;
}

.heroImage {
  width: 624px;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.heroTextContainer {
  margin-left: 120px;
}

.heroTitle {
  font-size: 48px;
  margin: 0 0 16px;
}

.heroDescription {
  line-height: 24px;
}

.sectionContainer {
  margin: 64px auto;
  max-width: 720px;
}

.sectionContainer h2 {
  margin: 0 0 32px;
  width: 100%;
  font-size: 36px;
}

.sectionContainer img {
  margin: 0 0 32px;
  width: 100%;
}

.sectionContainer h3 {
  font-size: 24px;
  margin: 24px 0 8px;
}

.sectionContainer p,
li {
  margin: 16px 0;
  line-height: 24px;
  color: #465a69;
}

.linkContainer {
  border: 1px solid #cdcdcd;
  padding: 16px 32px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  width: fit-content;
  cursor: pointer;
  margin: auto;
  color: black;
  text-decoration: none;
}

.linkContainer:visited {
  color: black;
}

.linkContainer h2 {
  font-size: 24px;
  margin-bottom: 8px;
}

@media screen and (max-width:600px) {

  .container {
    margin-top: 0;
  }

  .heroContainer {
    flex-direction: column;
  }

  .heroImage {
    width: calc(100% + 10vw);
    margin-bottom: 32px;
  }

  .heroTextContainer {
    margin: 0;
  }

  .heroTitle {
    font-size: 48px;
    margin: 0 0 16px;
  }

}