.homeContainer {
  display: flex;
  flex-direction: column;
}
.homeContainer {
  
}
.carouselWithHeader {
  background: #8547FF;
  padding-top: 64px;
  padding-bottom: 24px;
}
.desktop {
  display: none;
}
.mobileHeading {
  color: white;
  padding: 8px 0;
  text-align: center ;
}
@media screen and (min-width:600px) {
  .heading {
    color: black
  }
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
    height: 100vh;
    box-sizing: border-box;
    padding: 15vh 10vw;
    box-sizing: border-box;
    background-color: #F1F1F1;
  }
  .screenContainer {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 4px 4px 10px rgba(0,0,0,0.1);
  }
  .leftPane, .rightPane {
    flex:1;
    overflow: scroll;
  }
  .leftPane {
    background: #8547FF;
    color: white!important;
  }
  .rightPane {
    padding: 32px;
    box-sizing: border-box;
  }
}