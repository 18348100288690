.talent-container {
    padding: 80px 165px 26px;
    background: #FF194C;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.talent-title {
    width: 730px;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    color: #FFFFFF;
}

.talent-text {
    margin-top: 8px;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
}


.open-position-button {
    margin-top: 40px;
    background: #FFFFFF;
    border-radius: 6px;
    color: #FF194C;
    padding: 12px 32px;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    line-height: 24px;
}

.verticals {
    width: 440px;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}


@media screen and (max-width: 767px) {
    .talent-container {
        padding: 80px 40px 46px;
    }
    .talent-title {
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 46px;
        width: auto;
    }
    .verticals {
        flex-direction: column;
        width: auto;
    }
    .verticals div {
        margin-top: 10px;
    }
}