.heroContainer {
  background: #FF194C;
  color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 64px 20%;
}
.loaderContainer {
  height: 100vh;
  display: flex;
}

.heroContainer h1 {
  margin: 0;
  font-weight: bold;
  font-size: 48px;
}

.heroContainer p {
  color: white;
  text-align: center;
  margin: 24px 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

.container {
  padding: 0 0;
}
.valuesTitle {
  text-align: center;
  margin: 36px 0 0;
}
.valuesContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  flex: 1;
}

.valueContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1;
}
.valueContainer div {
  text-align: center;
}
.valueHeading {
  font-size: 20px;
}

.valueImage {
  height: 72px;
  width: 72px;
  object-fit: contain;
}

.accordianContent {
  display: flex;
  justify-content: space-between;
  padding: 36px 0;
  gap: 16px;
}

.sectionContainer {
  max-width: 1100px;
  margin: 32px auto;
  & p, li {
    line-height: 24px;
  }
 }

.chartContainer {
  flex: 1;
  height: 400px;
}

.chartContainerSmall {
  flex: 1;
  max-height: 300px;
}
.fullWidthChartContainer {
  width: 50%;
  height: 400px;
  margin: auto;
}
.thirdPartyLink {
  display: block !important;
  color: #FF194C;
}

@media screen and (max-width : 600px) {
  .heroContainer {
    padding: 64px 24px;
  }
  .heroContainer p {
    font-size: 16px;
    line-height: 24px;
  }
  .sectionContainer {
    margin: 0 16px 16px;
  }
  .valueContainer {
    flex-direction: column;
    gap: unset;
    width: 100%;
  }
  .accordianContent {
    flex-direction: column;
  }
  .chartContainer {
    flex: unset;
    height: 250px;
  }
  .fullWidthChartContainer {
    width: unset;
    height: 250px;
}
.valueText {
  text-align: center;
}
 }
