.heroContainer {
  display: flex;
  flex: 1;
  background: url('https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/HeaderCom1.png');
  padding: 180px 0 180px 100px;
  box-sizing: border-box;
  color: white;
  align-items: center;
  justify-content: space-between;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.subtitle {
  font-size: 16px;
  color: white;
  font-weight: bold;
  font-style: italic;
}
.heroContainer::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.2);
  left: 0;
  z-index: -100;
}

.heroHeading {
  margin: 0;
  font-size: 72px;
}

.leftContainer {
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rightContainer {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 64px 32px;
  border-radius: 32px 0 0 32px;
  box-sizing: border-box;
  width: 40vw;
}

.rightContainer * {
  color: white;
  font-weight: 600;
  line-height: 32px;
}

.italics {
  font-weight: bold;
  font-style: italic;
}

.button {
  background-color: #FF4D75;
  color: #fff;
  padding: 12px 64px;
  font-weight: bold;
  outline: none;
  border: none;
  font-size: 20px;
  border-radius: 4px;
  margin: 16px 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: min-content;
  margin-top: 80px;
}

.button:hover {
  transform: scale(1.02);
}

.heroContainer>button {
  display: none;
}

@media screen and (max-width: 600px) {

  .heroContainer * {
    font-size: 14px;
  }

  .heroContainer {
    flex-direction: column;
    padding: 120px 16px 32px;
    gap: 16px;
  }

  .rightContainer {
    width: 100%;
    border-radius: 8px;
    padding: 24px;
    margin: 16px 0;
  }

  .leftContainer {
    width: 100%;
  }

  .leftContainer img {
    width: 80vw;
  }

  .leftContainer button {
    display: none;
  }

  .heroContainer>button {
    display: block;
    margin: 0;
  }

  .rightContainer * {
    line-height: 24px;
  }
  .button {
    font-size: 16px;
  }
  .subtitle {
    font-size: 14px;
  }
}