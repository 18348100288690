.container {
  max-width: 700px;
  margin: 72px auto;
}

.cardsContainer h1 {
  font-size: 36px;
  margin-bottom: 48px;
  position: absolute;
  left: -32px;
  top: -32px;
  transform: translateX(-100%);
}
.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
}

.cardContainer {
  width: 45%;
  cursor: pointer;
  margin-bottom: 32px;
}
.cardImage {
  width: 100%;
  aspect-ratio: 4/3;
}

.cardContainer h2 {
  font-size: 24px;
  margin-bottom: 0;
}
.ctaLink {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.ctaLink p {
  font-weight: 600;
}


@media screen and (max-width: 600px) {

  .container {
    margin-top: 32px;
  }
  .cardsContainer {
    flex-direction: column;
    padding: 0 16px;
  }
  .cardsContainer h1 {
    font-size: 36px;
    margin: 0 0 48px;
    position: relative;
    left: 0px;
    top: 0px;
    transform: translateX(0);
  }
  .cardContainer {
    width: 100%;
    margin-bottom: 48px;
  }
  .cardContainer h2 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .cardContainer p {
    margin-top: 4px;
  }
}
