.more-frnd-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}


.more-frnd-container {
    display: flex;
    overflow: hidden;
}

.more-frnd-section {
    width: 100vw;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.more-frnd-link {
    border-radius: 26px;
    border: 1px solid #48B2FF;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #48B2FF;
    padding: 12px 18px;
    margin-top: 16px;
}

.more-frnd-carousel {
    display: flex;
    transform: translateX(0);
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
}