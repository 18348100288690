@media (max-width: 350px) {
  .docContainer {
    padding: 60px 10px 4px; /* Padding थोड़ा adjust किया */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Space-between से center किया */
    align-items: center;
    width: 100%;
    height: auto;
    word-break: break-word;
    text-align: justify;

  }
}

.frndcommunity{
  text-align: center;
}

@media (min-width: 351px) and (max-width: 640px) {
  .docContainer {
    padding: 80px 20px 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    word-break: break-word;
    text-align: justify;
  }
}

@media (min-width: 641px) {
  .docContainer {
    max-width: 900px;
    margin: auto;
    padding: 84px 16px 24px;
    text-align:left;
  }
}


table, th, td {
  border: 1px solid black;
  border-collapse: collapse;




}
.verticalalign
{
  vertical-align: middle;
}

p {
  margin: 8px 0;
  text-align: left;
}
.font-italic{
  font-style: italic
}
th, td {
  padding: 8px;
}

th {
  background-color: rgba(0,0,0,0.1);
}

td {
  vertical-align: top;
}

table {
  margin: 12px 0;
}

a {
  color: blue;
  text-decoration: underline;
}

.aligncenter{


  h3 {
    display: block;
    width: 100%;
    text-align: center;
  }



}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

th, td {
  width: 33.33%;

  border: 1px solid black;

}
 .privacytable {
    width: 100%;
    border-collapse: collapse;
  }

  .privacytable th,
  .privacytable td {
    border: 1px solid black;
    padding: 4px;
    text-align: left;
  }

  .privacytable th:first-child,
  .privacytable td:first-child {
    width: 30%;
  }

  .privacytable th:nth-child(2),
  .privacytable td:nth-child(2) {
    width: 40%;
  }

  .privacytable th:last-child,
  .privacytable td:last-child {
    width: 30%;
  }

  .frndcommunity{
    text-align: center;
  }