.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.container .heading {
  align-self: flex-end;
  font-size: 24px;
  text-align: center;
  margin: 0;
}

.container .subheading {
  text-align: center;
  font-size: 28px;
  margin: 0;
  font-weight: 900;
  font-style: italic;

}

.container .divider {
  height: 3px;
  width: 180px;
  background-color: rgb(255, 52, 106);
  margin: 0 16px;
}


@media screen and (max-width: 600px) {
  .container {
    justify-content: center;
  }

  .divider {
    flex: 1;
    margin: 0;
    width: 100%;
    margin-left: 4px;

  }

  .container .heading {
    font-size: 16px;
  }
}