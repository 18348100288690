.Accordion {
  border-radius: 4px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  height: auto;
}

.Accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 20px 0 0;
}

.Accordion-header-question {
  font-weight: bold;
  user-select: none;
}

.Accordion-header-icon {
  font-size: 24px;
  line-height: 1;
  user-select: none;
}

.Accordion-answer {
  padding: 0px;
  opacity: 0;
  max-height: 0;
  transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
  padding: 12px 0;
  user-select: none;
}

.Accordion-answer--expanded {
  opacity: 1;
  max-height: 200px;
}
