.usp-container {
    padding: 60px 12%;
}

.usp-point-container {
    width: 210px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.usp-points {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
}

.usp-title {
    font-weight: 900;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    color: #18191F;
}

.usp-text {
    margin-top: 20px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #18191F;
}


@media screen and (max-width: 767px) {
    .usp-points {
        margin-top: 40px;
        flex-direction: column;
        align-items: center;
    }
    .usp-point-container {
        margin-bottom: 30px;
    }
    .usp-points:last-child {
        margin-top: 0px;
    }
}