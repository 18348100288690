.homeContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;

}

.carouselWithHeader {
  padding-bottom: 48px;
  padding-top: 100px;
}

.buttonsContainer {
  padding: 16px;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  position: fixed;
  background-color: white;
  z-index: 100;
  box-shadow: 2px -4px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in;
}

.buttonContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.rechargeCoinsText {
  display: none;
}

.desktopCarousel {
  display: none;
}


.appPitch {
  padding: 16px;
}

.appPitchDetails {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.appPitchIcon {
  width: 72px;
  height: 72px;
  border-radius: 8px;
  margin-right: 16px;
}

.appPitchName {
  margin: 0 0 6px;
  font-size: 24px;
}

.appPitchDescription {
  color: green;
  font-weight: 600;
  margin: 0;
  font-size: 16px;

}

.appPitchStats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.appPitchRating {
  padding: 8px;
  text-align: center;
  flex:1;

}
.appPitchRatingTitleStar {
  font-size: 10px;
  margin-left: 4px;
}
.appPitchRatingTitleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.appPitchRatingTitle {
  margin: 0 0 4px;

}

.appPitchRatingSubtitle {
  margin: 0;
  font-size: 12px;
  color: rgba(126, 126, 126, 1);
}

.appPitchDownload {
  padding: 8px;
  text-align: center;
  flex:1;


}

.appPitchDownloadTitle {
  margin: 0 0 4px;
}

.appPitchDownloadSubtitle {
  margin: 0;
  font-size: 12px;
  color: rgba(126, 126, 126, 1);
}

.appPitchAge {
  padding: 8px;
  flex:1;
  text-align: center;

}
.appPitchStatsDivider {
  height: 24px;
  background-color: #ccc;
  width: 1px;
  margin: auto;
}
.appPitchAgeTitle {
  margin: 0 auto 4px;
  padding: 0 4px;
  border: 1px solid #bbb;
  align-self: center;
  width: min-content;
}

.appPitchAgeSubtitle {
  margin: 0;
  font-size: 12px;
  color: rgba(126, 126, 126, 1);


}

.appPitchButton {
  margin-bottom: 32px;
}

.pitchCarousel {
  display: flex;
  gap: 8px;
  overflow: scroll;
}

.pitchCarousel::-webkit-scrollbar {
  display: none;
}

.appRechargeButton {
  display: none;
}
.pitchCarouselElement, .pitchCarouselVideoElement {
  height: 200px;
  border-radius: 16px;
  aspect-ratio: 5/8;
  object-fit: cover;

}

.pitchCarouselVideoElement {
  aspect-ratio: 4/3;
}
@media screen and (min-width: 600px) {
  .desktopCarousel {
    display: block;
  }

  .appPitch {
    display: none;
  }

  .buttonContainer {
    margin: 0;
  }
  .downloadAppButton {
    display: none;
  }
  .carouselWithHeader {
    padding-bottom: 50px;
    margin-bottom: 64px;
    position: relative;
    padding-top: 100px;
    background: url('background.webp') no-repeat top center;
    background-size: cover;
  }

  .rechargeCoinsText {
    display: block;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin-bottom: 16px;
  }

  .appRechargeButton {
    display: block;
  }
  .buttonsContainer {
    position: absolute;
    bottom: 0;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
    width: 50vw;
    transform: translate(50%, 50%);
    border-radius: 100px;
    padding: 16px 64px;
    align-self: center;
  }

  .homeContainer {
    padding-bottom: 0;
  }
}