.container {
  position: fixed;
  height: 100vh;
  width: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00744F;
  z-index: 1000000;
  flex-direction: column;

}

.backgroundImg {
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: absolute;
  z-index:-5;
}

.wohooImg {
  width: 150px;
}

.savingsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 16px;
}

.priceText {
  color: black;
  font-weight: 800;
  background-color: white;
  padding: 4px;
  border-radius: 4px;
  font-size: 20px;
  border: 2px solid black;
}

.savingText {
  font-weight: 700;
  color: white;
  font-size: 20px;
}
.closeWhiteImg {
  position: fixed;
  top: 32px;
  right: 32px;
}

.saveMoreImg {
  position: fixed;
  bottom: 100px;
  width: 200px;
  margin-top: 64px;
}

.bigConfetti {
  width: 100vw;
  height: 100vh;
  position: absolute;
  object-fit: cover;
  z-index:-5;

}

.confettiSmall,
.confettiSmall2
{
  width: 48px;
  height: 48px;
}

.confettiSmall2 {
  transform: rotateZ(270deg)
}
@media screen and (min-width: 600px) {
  .container {
    display: none;
  }
}
