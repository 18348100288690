.carouselContainer {
  box-sizing: border-box;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100vw;
  align-self: center;
  height: 600px;
}

.title {
  position: absolute;
  top: 32px;
}

.carousel {
  width: 100vw;
}

.carouselInner {
  display: flex;
  transition: all 0.3s ease-in-out;
  /* Smooth sliding transition */
  padding: 0 27.5vw;
}

.slide {
  min-width: 55vw;
  /* padding: 20px; */
  /* background-color: #eee; */
  padding: 16px 32px;
  /* margin: 16px; */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detailsContainer {
  background: rgba(34, 34, 34, 1);
  color: white;
  border-radius: 0 16px 16px 0;
  padding: 0 64px 0 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  height: 70%;
}

.detailsContainer h1 {
  font-size: 24px;
  margin: 0;
}

.detailsContainer p {
  color: rgba(192, 192, 192, 1);
  margin: 0;
}

.detailsContainer a {
  color: rgba(161, 182, 255, 1);
  cursor: pointer;
}


.activeImage,
.inactiveImage {
  aspect-ratio: 4/3;
  object-fit: cover;
  transition: all 0.3s ease;
}


.activeImage {
  height: 300px;
  filter: none;

}

.inactiveImage {
  height: 200px;
  filter: grayscale(100%);
}

/* .slide:nth-child(1) img {
  filter: grayscale(100%); 
}

.slide:nth-child(3) img {
  filter: grayscale(100%); 
}

.slide:nth-child(2) img {
  filter: none; 
} */

/* Arrow buttons */
.rightArrow,
.leftArrow {
  background-color: rgba(255, 255, 255, 0.56);
  color: white;
  border: none;
  cursor: pointer;
  z-index: 10;
  position: absolute;
  border-radius: 32px;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in all;
}

.rightArrow img,
.leftArrow img {
  width: 16px;
  height: 16px;
}

.leftArrow:hover,
.rightArrow:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.leftArrow {
  left: 20px;
  transform: translateY(-50%) rotate(180deg);
}

.rightArrow {
  right: 20px;
  /* transform: rotate(-90deg); */

}

.dotsContainer {
  display: flex;
  position: absolute;
  bottom: 32px;
  gap: 8px;

}

.dot,
.activeDot {
  width: 8px;
  height: 8px;
  background: rgba(217, 217, 217, 1);
  border-radius: 32px;
}

.activeDot {
  background: rgba(83, 83, 83, 1);
}

@media screen and (max-width: 600px) {
  .carouselContainer {
    height: 700px;
  }

  .carouselInner {
    padding: 0;
  }

  .slide {
    min-width: 100vw;
    flex-direction: column;
    padding: 16px;
  }

  .activeImage,.inactiveImage {
    width: 100%;
    height: unset;
    z-index: 1000;
  }

  .detailsContainer {
    border-radius: 0 0 16px 16px;
    padding-top: 64px;
    padding-bottom: 32px;
    width: 100%;
    margin-top: -16px;
  }
  .title {
    width: 100%;
  }
}