.container {
  flex: 1;
  display: flex;
  cursor: pointer;
  align-items: stretch;
  transition: all 0.2s ease;
  border-radius: 8px;
}

.container:hover {
  transform: scale(1.02);
}

.imageContainer {
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 2px white solid;
}
.image {
  width: 100%;
  object-fit: cover;
  min-height: 100%;
}

.textContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  background-color: #eee;
  padding: 32px 0;
  justify-content: center;
}

.title {
  font-size: 32px;
  width: 70%;
  margin: 0 32px 0;
}

.cta {
  margin-left: 32px;
}
.imageContainerHorizontal {
  border: none;
}

@media screen and (max-width: 600px) {
  .container {
  }
  
  .imageContainerHorizontal {
    flex: 1.5;
  }
  .title {
    font-size: 20px;
    width: 70%;
    margin-bottom: 0;
  }

  .title {
    margin: 16px;
  }
  
  .cta {
    margin: 0 16px 16px;
  }
  .textContainer {
    padding: 0;
  }

  .imageContainer {
    border-bottom: none
  }

}