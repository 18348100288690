.superContainer {
  padding: 100px;
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.container {
  display: flex;
  flex: 1;
  gap: 64px;
  align-items: center;
  justify-content: space-between;
}

.container img {
  height: 500px;
  aspect-ratio: 4/3;
}

.textContainer span {
  font-weight: bold;
  font-style: italic;
  color: #777;
}

.textContainer {
  overflow: hidden;
  padding: 64px;
  border-radius: 16px;
  width: 30vw;
  background: linear-gradient(#fff 0 0) padding-box,
  linear-gradient(to right, #7B1F27 0.65%, #FFFFFF 97.65%) border-box;
  border: 5px solid transparent;
  border-width: 2px 0px 2px 4px;
border-radius: 16px;
}

.textContainer h2 {
  font-size: 24px;
  font-weight: bold;
  font-style: italic;
}

.textContainer p {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;

}

@media screen and (max-width: 600px) {
  .superContainer {
    padding: 64px 16px;
    gap: 32px;
    box-sizing: border-box;
  }

  .container {
    flex-direction: column-reverse;
    width: 100%;
    gap: 32px;
  }

  .container img {
    height: unset;
    width: 100%;
  }

  .textContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    text-align: center;

  }
  .textContainer h2 {
    font-size: 16px;
  }
  .textContainer p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}