.heroContainer {
  display: flex;
  flex: 1;
  background-color: #FBBB1832;
  padding-left: 100px;
  padding-top: 72px;
  box-sizing: border-box;

}
.formTextFieldsContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;
}
.formTextField {
    background: white;
    border: 1px solid #eee;
    outline: none;
    border-radius: 4px;
    color: black;
    padding: 16px;
    width: calc(100%);
    margin: 16px 0;
    font-weight: bold;
    box-sizing: border-box;
    height: min-content;
}
.heroContent {
  flex:1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* .heroContent h1 {
  font-size: 64px;
  margin-bottom: 0;
} */

.heroHeading {
  font-size: 48px;
  margin-bottom: 0;
  
}
/* .heroContent p {
  color: #353535;
  font-size: 20px;
  margin: 8px 0 24px;
} */
.heroGrid {
  display: flex;
  flex-direction: column;
  /* gap: 4px; */
  align-items: flex-end;
}
.message {
  min-height: 20px;
  font-size: 14px;
}
.heroGridRow {
  display: flex;
}
.heroGridImage {
  height: 170px;
  width: 150px;
  object-fit: cover;
  margin: 0;
}

.button {
  background-color: #FF194C;
  color: #fff;
  padding: 12px 64px;
  font-weight: bold;
  outline: none;
  border: none;
  font-size: 20px;
  border-radius: 4px;
  margin: 16px 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: min-content;
}
.button:hover {
  transform: scale(1.02);
}

.radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid transparent;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  outline: 1px solid black;
  background-color: transparent;
}

.radio:checked {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #FFEFF8;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  outline: 1px solid black;
  background-color: #FF194C;
}

.radioLabel {
  margin: 16px 0;
  font-weight: bold;
  color: black;
  align-items: center;
  display: flex;
}


@media screen and (max-width: 600px) {
  .heroContainer {
    flex-direction: column;
    padding: 72px 16px 0;
    gap: 32px;
  }
  .heroGrid {
    display: none;
  }
  .heroGridImage {
    width: 25vw;
    object-fit: cover;
    margin: 0;
  }
  .hero-subheading {
    width: 90%;
  }
  .formTextFieldsContainer {
    flex-direction: column;
    gap: 0;
  align-items: flex-start;
  }
}