.media-container {
    padding: 64px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F4F5F7;
}

.media-title {
    text-align: center;
    font-weight: 900;
    margin-bottom: 40px;
    font-size: 48px;
    line-height: 60px;
    color: #18191F;
    font-family: "Lato", sans-serif;
}

.media-coverage-block {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-evenly;
}

.coverage {
    width: 255px;
    margin-right: 30px;
}

.media-text {
    margin-top: 8px;
    font-size: 16px;
    line-height: 26px;
    color: #474A57;
}


@media screen and (max-width: 767px) {
    .media-coverage-block {
        flex-direction: column;
        width: 100%;
    }
    .coverage {
        margin-bottom: 40px;
        margin-right: 0px;
    }
    .media-title {
        padding: 0 7%;
        font-size: 32px;
    }
}