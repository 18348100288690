.container {
  /* margin: 0 16px 24px; */
  display: flex;
  align-items: center;
  flex-direction: column;;
  position: relative;
  padding: 0 0 24px;
}

.desktop {
  padding: 0px 0;
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  width: 100%;
}

.packageLabel {
  color: #828282;
  font-weight: bold;
}

.changePackage {
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  color: #ff4f6d
}

.billContainer {
  /* background-color: #F5F6F7; */
  box-shadow: 0px 1px 9px #00000020;
  border-radius: 12px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #eee;

}
.rowHighlight {
  background-color: #E5FFF7;
}


.rowWithDivider {
  background-image: linear-gradient(to right, #ddd 33%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 20px 1px;
  background-repeat: repeat-x;
}

.row,
.rowWithDivider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.coinsLabel {
  font-size: 14px;

}

.coinsValue {
  font-size: 14px;
  font-weight: bold;

}

.rowWithoutDivider {
  border: none;
}

.couponsLabel {
  font-size: 14px;

}

.couponsValue {
  font-size: 14px;
  font-weight: bold;
  color: #1BB785;

}

.finalLabel {
  font-weight: bold;

}

.finalValue {
  font-weight: bold;

}

.hiddenupiSelectorContainer {
  transform: translateY(300px);
}

.upiSelectorContainer {
  position: absolute;
  top: 40px;
  width: 100%;
  background: white;
  margin: 16px 0;
  height: 100px;
  transition: all 0.2s ease-in;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.upiOptions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 16px;

}
.selectorContainer {
  width: 100%;
  background: white;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timerText span {
  font-weight: bold;
}

.button {
  width: 90%;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.morePackagesButton {
  font-size: 12px;
  color: #23A2FF;
  font-weight: bold;
}

.actionCenter {
  position: fixed;
  box-sizing: border-box;
  margin: 16px 0 0;
  bottom: 0px;
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  background: white;
}

.paymentOptions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 24px 0;
}

.selectedPaymentContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F5F6F7;
  margin-bottom: 8px;
  padding: 8px 16px;
  box-sizing: border-box;

}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 24px;
}

.morePaymentOptionText {
  color: #a4a4a4;
  font-size: 12px;
  margin-right: 4px;
}

.morePaymentOptionIcon {
  width: 16px;
  height: 12px;
  object-fit: contain;
  transform: rotate(270deg);
  opacity: 0.4;
  transition: all 0.2s ease-in;
}

.morePaymentsIconRotate {
  transform: rotate(90deg);

}

.paymentIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.selectedIcon {
  display: none;
  width: 6px;
  height: 6px;
  position: absolute;
  top: -1px;
  right: -1px;
}

.selectedOption {
  border: 1px solid #3ABC76;
}

.selectedOption .selectedIcon {
  display: block;
}

.paymentOptionText {
  font-weight: bold;
  font-size: 16px;
  margin-left: 4px;
}

.selectedOption .paymentOptionText {
  font-weight: bold;
}

.leftPane {
  display: none;
}

.content {
  height: 500px;
  overflow: scroll;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.contentBig {
  height: 400px;
  overflow: scroll;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.upiOption {
  position: relative;
  border-radius: 18px;
  width: 24px;
  height: 24px;
}

.largePaymentOptionContainer {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.heroContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heroImage {
  width: 100vw;
  aspect-ratio: 16/9;
  object-fit: contain;
}

.safetyImage {
  width: 60vw;
  object-fit: contain;
  aspect-ratio: 2;
}

.upiOptionLarge {

  border-radius: 48px;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  display: flex;
}


.upiOptionLarge .paymentIcon {
  height: 36px;
  width: 36px;
  margin: auto;
}

.upiOptionLarge .selectedIcon {
  height: 12px;
  width: 12px;
  margin: auto;
}

.desktopButton {
  display: none;
}
@media screen and (min-width:600px) {
  .desktopButton {
    display: block;
  }
  .actionCenter {
    display: none;
  }
  .upiOption {
    display: none;
  }

  .desktop {
    display: block;
    height: 100vh;
    box-sizing: border-box;
    padding: 15vh 10vw;
    box-sizing: border-box;
    background-color: #F1F1F1;
  }

  .screenContainer {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .leftPane,
  .rightPane {
    flex: 1;
    overflow: scroll;
  }

  .leftPane {
    display: block;
    background: #8547FF;

    color: white!important;
  }

  .rightPane {
    padding: 32px;
    box-sizing: border-box;
    overflow: scroll;
  }

  .button {
    position: absolute;
    bottom: 0;
    margin: 0;
    width: 100%;
  }

  .container {
    height: 100%;
  }

  .content, .contentBig {
    height: unset
  }
  .heroContainer {
    display: none;
  }
}