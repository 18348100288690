.profile-header {
    background-color: black;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile-header-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

.profile-header-items {
    display: flex;
    align-items: center;
}