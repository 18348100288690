.profile-card {
    border-radius: 16px;
    background: white;
    position: relative;
    top: -16px;
}
.profile-card-top {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    height: 42px;
}
.profile-name {
    font-size: 21px;
    font-weight: 700;
    line-height: 32px;
    color: #212121;
}

.profile-avatar {
    position: relative;
    background: white;
    border-radius: 200px;
    top: -42px;
    border: 1px solid #DFE2E6;
    outline: 3px solid white;
}

.profile-follow {
    border: 1px solid #48B2FF;
    border-radius: 26px;
    font-weight: 700;
    color: #48B2FF;
    height: 40px;
    width: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-details {
    padding: 0px 24px;
    color: #A4A4A4;
}

.profile-data {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #7E7E7E;
    align-items: center;
}

.profile-language {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #A4A4A4;
    margin-top: 6px;
    margin-bottom: 8px;
}

.profile-followers {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #A4A4A4;
}

.profile-data-icon {
    margin-right: 6px;
}