.desktopHeader,
.mobileHeader {
    height: 72px;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 1000;
    padding: 0 100px;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.2s ease-in-out;
    position: fixed;
}


.desktopHeader {
    display: none;
}

.rightIcon {
    height: 20px;
    width: 20px;
}

.scrolledHeader,
.activeHeader,
.desktopHeader {
    background: white;
    box-shadow: 1px 1px 1px #eee;
}

.mobileHeader {
    display: flex;
    padding: 8px 24px;
    width: 100%;
    box-sizing: border-box;

}

.mobileMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    height: calc(100vh - 70px);
    margin-top: 72px;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
    transition: transform 0.2s ease-in;
    transform: translateX(100%);
    background-color: #fff;
    padding: 0;
    box-sizing: border-box;
}

.logo {
    height: 24px;
}

.logo img {
    height: 24px;
    margin: 0;
}

.logoText {
    font-weight: bold;
}

.active {
    transform: translateX(0);
}


.navItems {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    padding: 0;
    padding-bottom: 10vh;
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    width: 100%;
}

.menuList {
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
}

.menuListItem {
    margin: 0;
    padding: 16px 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.menuListItem a {
    text-decoration: none;
    color: #000;
    font-size: 18px;
    transition: color 0.2s ease-in-out;
    display: flex;
}

.languageDropDownContainer {
    align-self: flex-start;
    padding-left: 24px;
}

.mobileMenuIcon img {
    height: 24px;
    cursor: pointer;
}

.invertColor {
    filter: invert(1);
}

.rightItems {
    flex-direction: column-reverse;
    gap: 16px;
}

.logoTextContainer {
    display: flex;
    align-items: center;
}

.leftIcon {
    height: 16px;
    margin-right: 8px;
}

.red-link {
    color: #FF194C !important;
    font-weight: 600;
}

.black-link {
    font-weight: 600;
}

.loginItem {
    background-color: #eee;
    padding-top: 24px;
    margin-top: 0;
}

.loginItemContainer {
    display: flex;
    position: relative;
    align-items: center;
}

.loginCaret {
    height: 16px;
    position: absolute;
    right: 0;
}

.loginSubHeading {
    font-weight: normal;
    font-size: 14px;
}

.loginItemContainer {
    display: flex;
}

.avatar {
    width: 54px;
    height: 54px;
    margin-right: 16px;

}

.downloadAppButton {
    font-size: 12px;
    margin-bottom: 0;
    margin-right: 8px;
    padding: 8px 8px
}

.downloadAppButton img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

@media screen and (min-width: 600px) {

    .downloadAppButton {
        display: none;
    }

    .mobileHeader {
        display: none;
    }

    .desktopHeader {
        display: flex;
        width: 100%;
        box-sizing: border-box;
    }

    .google-badge img {
        height: 40px;
    }

    .logo {
        height: 32px;
    }

    .logo img {
        height: 32px;
        margin: auto 0px auto 0;
    }

    .navItemsDesktop {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        flex: 1;
        padding-right: 32px;
    }
    
    
    .rightItemsDesktop {
        display: flex;
        height: 40px;
        align-items: center;
        gap: 16px;
    }

    .loginButton {
        background-color: #009A66;
        color: white;
        cursor: pointer;
        font-weight: bold;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 0 24px;
        border-radius: 8px;
    }

    .menuListItem {
        margin: 16px 0 16px 24px;
        border-bottom: none;
        padding: 0;
    }

    .menuListItem a {
        font-size: 14px;
    }


    .avatar {
        width: 32px;
        height: 32px;
    }

    .loginHeading {
        font-size: 14px;
        font-weight: bold;
    }

    .loginSubHeading {
        font-size: 10px;
    }

    .loginItemContainer {
        padding-right: 24px;
        position: relative;
        cursor: pointer;
        user-select: none;
    }

    .loginCaret {
        transform: rotate(90deg);
    }

    .logoutContainer {
        position: absolute;
        bottom: -16px;
        transform: translateY(100%);
        background-color: white;
        width: 100%;
        padding: 32px 16px;
        box-sizing: border-box;
        border-radius: 8px;
        box-shadow: 6px -6px 4px rgba(0, 0, 0, 0.1);
    }

    .activeContainer {
        transform: translateY(100%);
    }
}