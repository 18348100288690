.carouselImage {
  object-fit: contain;
  max-width: 90%;
  /* max-height: 100%; */
}

.carouselContainer {
  /* height: 20vw; */
  width: 35vw;
  align-self: center;
  display: flex;
  margin: auto;
  flex-direction: column;
  padding: 48px 0;
  /* align-items: center; */
}

.slickDot {
  height: 5px;
  width: 18px;
  border-radius: 18px;
  bottom: 10px;
  background-color: #FFFFFF7F;
  margin: 0;
}

.slickDots20 {
  bottom: -15px;
}
.slickDots {
  bottom: -40px;
}

@media screen and (min-width:600px) {
  .homeCarousel{
    width: 80vw;
  }
}