.faqContainer {
  max-width: 90vw;
  margin: 16px auto;
}

.question {
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 8px;
}

.answer {
  font-size: 12px;
  margin-bottom: 24px;
}