.container{
display: flex;
padding: 4px 8px;
align-items: center;
margin: 8px 0;
border: 1px solid #4C25B932;
transition: 0.2s ease background-color;
border-radius: 8px;
position: relative;
box-sizing: border-box;
width: 100%;
}

.valid {
  background-color: #4C25B918;
}
.invalid {
  
  border: 1px solid #D13A3A;
  background-color: #D13A3A18;
}

.left{
  flex: 1;
  display: flex;
  align-items: center;
}
.offerIcon {
  height: 16px;
  margin-right: 8px;
  color: #a4a4a4;
}

.applyButton{
  font-size: 12px;
  font-weight: bold;
  background-color: #4C25B9;
  padding: 8px 16px;
  /* height: 100%; */
  border-radius: 4px;
  color: white;
  cursor: pointer;

}
.couponInput {
  flex:1;
  border: none;
  outline: none;
  padding: 4px;
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  color: #4C25B9;
  background-color: transparent;
  
}

.appliedTextContainer, .invalidTextContainer{
display: flex;
align-items: center;
font-size: 14px;
font-weight: bold;
}
.appliedTextContainer{
  color: #4F28BC
}

.invalidTextContainer {
  color: #D13A3A;
}

.icon {
  height: 16px;
  margin-right: 4px;
}

.clearIcon {
  position: absolute;
  right: 0;
  top: -8px;
  width: 16px;
}