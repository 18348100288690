.app-pitch-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

.app-pitch-container {
    background: #F5F6F7;
    border-radius: 16px;
    margin: 8px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app-pitch-img {
    margin-top: 24px;
    margin-bottom: 16px;
}

.app-pitch-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #474747;
    margin: 16px 88px;
}

.app-pitch-online-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

.avatar-img {
    height: 48px;
    margin-top: 48px;
    margin-bottom: 8px;
}

.profile-data-img {
    height: 16px;
    margin-right: 5px;
}
.profile-section {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 16px;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #171717;
}
.app-pitch-btn {
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: center;
}

.app-pitch-download {
    background: #171717;
    border-radius: 28px;
    color: white;
    padding: 16px 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    width: 100%;
}


.app-pitch-pros {
    display: flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    justify-content: space-between;
    color: #A4A4A4;
    margin-bottom: 64px;
    margin-top: 48px;
}


.app-pitch-columns {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.profile-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.app-pitch-playstore {
    margin-top: 16px;
}

.app-pitch-section-text {
    margin-top: 10px;
}