.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container .prefix {
  font-size: 40px;
  align-self: flex-start;
  margin: 0
}

.container .heading {
  align-self: flex-end;
  font-size: 24px;
}
.slantDivider {
  width: 3px;
  height: 56px;
  background-color: rgb(255, 52, 106);
  margin: 0 16px 0 8px;
  transform: rotateZ(20deg);
}

.container .divider {
  height: 3px;
  width: 180px;
  background-color: rgb(255, 52, 106);
  margin-left: 16px;
}


@media screen and (max-width: 600px) {
  .divider1 {
    height: 3px;
    flex: 1;
    background-color: rgb(255, 52, 106);
    margin-right: 4px;
    display: none;

  }
  .container {
    justify-content: center;
  }
  .divider {
    flex: 1;
    margin: 0;
    width: unset;
    margin-left: 4px;
    display: none;
  }

  .container .heading {
    font-size: 16px;
  }
  .container .prefix {
    font-size: 24px;
  }
}